@font-face {
  font-family: 'CARESYMASTM';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../assets/CARESYMASTM.otf) format('opentype');
}

.SymbolList {
  padding: 10px 0;
}

.SymbolItem {
  padding: 5px 0;
}

.SymbolGlyph {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;
  top: 3px;
  text-align: center;
  font-family: 'CARESYMASTM', sans-serif;
  font-size: 18px;
}

.SymbolDescription {
  display: inline-block;
  padding-left: 10px;
}