@import url(https://unpkg.com/leaflet@1.7.1/dist/leaflet.css);

.TraceMap {
  width: calc(100% - var(--app-gutter-size) * 2);
  height: 450px;
  margin: 0 var(--app-gutter-size);
}

.TraceMapPopup {
  text-align: center;
}

.TraceMapPopup h1,
.TraceMapPopup h2,
.TraceMapPopup h3,
.TraceMapPopup h4 {
  padding-bottom: 0;
}
