.Title {
  padding: 0 var(--app-gutter-size) 20px;
  border-bottom: 2px solid var(--app-border-color);
}

.TitleLabel {
  color: var(--app-bold-2nd-color);
  font-weight: var(--app-strong-font-weight);
}

.TitleInfoLine {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.TitleInfoSeparator {
  position: relative;
  margin: 0 14px;
  border-left: 2px solid var(--app-border-3rd-color);
}

.TitleInfoCost {
  color: var(--app-bold-color);
  padding-top: 3px;
}

.TitleInfoLink {
  display: flex;
  flex-direction: row;  
  align-items: center;
}