.ExperiencePage {
  background-color: var(--app-background-color);
  max-width: 450px;
  min-width: 320px;
  margin: 0 auto;
}

.ExperiencePage header {
  text-align: center;
  padding: 40px 0 30px;
  animation-duration: 1.25s;
  animation-name: flip;
}

.ExperiencePage header h2 {
  color: var(--app-highlight-color);
  font-size: 22px;
  font-weight: var(--app-thin-font-weight);
}

.ExperiencePage main {
  background-color: var(--app-background-color);
  height: 100%;
  animation-duration: 1s;
  animation-name: fadeInUp;
}