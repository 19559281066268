.IntroImage {
  background-color: var(--app-background-dark-color);
  display: flex;
  justify-content: stretch;
  border: 20px solid var(--app-background-color); 
  min-height: 375px;
  transition: height 0.5s linear;
}

.IntroImage.loaded {
  background-color: var(--app-background-color);
  border: 0;
}

.IntroImage > img {
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.IntroImage > img.loaded {
  opacity: 1;
}

.IntroImageCaption { 
  text-align: center;
  padding: 10px 0;
}

.IntroText {
  padding: 10px var(--app-gutter-size);
}

.IntroFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 24px var(--app-gutter-size) 34px;
  padding: 10px 0;
  border: 2px solid var(--app-border-3rd-color);
}

.IntroFooterColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 25%;
  padding-top: 5px;
}

.IntroFooterColumn.wide {
  align-items: flex-start;
  width: 50%;
  padding-left: 22px;
}

.IntroFooterColumn.separators {
  border-left: 2px solid var(--app-border-3rd-color);
  border-right: 2px solid var(--app-border-3rd-color);
}

.IntroFooterColumn.action {
  cursor: pointer;
}

.IntroFooterColumn > h1 {
  color: var(--app-bold-2nd-color);
  font-weight: var(--app-regular-font-weight);
  padding: 0;
}

.IntroFooterColumn > h4 {
  padding: 0;
}

.IntroFooterColumn > img {
  width: 24px;
  height: 24px;
}
