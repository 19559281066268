@import url(./assets/normalize.css);
@import url(./assets/animate.min.css);

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v14/iJWKBXyIfDnIV7nBrXw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --app-regular-font-family: Rubik, sans-serif;

  --app-background-color: #fbfbfb;
  --app-background-light-color: #ffffff;
  --app-background-dark-color: #efefef;
  --app-background-body-color: #dfdfdf;

  --app-regular-color: #4e4e4e;
  --app-dark-2nd-color: #333333;
  --app-highlight-color: #d2b48c;
  --app-bold-color: #121924;
  --app-bold-2nd-color: #858585;

  --app-border-color: #efefef;
  --app-border-2nd-color: #858585;
  --app-border-3rd-color: #d7d7d7;

  --app-regular-font-weight: 400;
  --app-thin-font-weight: 300;
  --app-strong-font-weight: 500;

  --app-gutter-size: 20px;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

body {
  background-color: var(--app-background-body-color);
  color: var(--app-regular-color);
  font-family: var(--app-regular-font-family);
  font-weight: var(--app-regular-font-weight);
  font-size: 14px;
  line-height: 22px;
}

h1 {
  color: var(--app-bold-color);
  font-weight: var(--app-strong-font-weight);
  font-size: 21px;
  line-height: 24px;
  padding: 5px 0;
  margin: 0;
}

h2 {
  color: var(--app-bold-2nd-color);
  font-weight: var(--app-regular-font-weight);
  font-size: 18px;
  line-height: 24px;
  padding: 5px 0;
  margin: 0;
}

h3 {
  color: var(--app-dark-2nd-color);
  font-weight: var(--app-regular-font-weight);
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px 0;
}

h4 {
  color: var(--app-bold-2nd-color);
  font-weight: var(--app-regular-font-weight);
  font-size: 12px;
  padding: 5px 0;
}

strong {
  color: var(--app-bold-color);
  font-weight: var(--app-strong-font-weight);
}

ul {
  list-style-type: none;
}

a {
  color: var(--app-bold-color);
  font-weight: var(--app-regular-font-weight);
}

button {
  background-color: transparent;
  color: var(--app-bold-color);
  font-weight: var(--app-strong-font-weight);
  text-decoration: underline;
  border: unset;
  margin: 10px 0;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  body {
    background-color: var(--app-background-color);
  }

  .noAnimationOnMobile {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }
}