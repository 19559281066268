footer {
  background-color: var(--app-background-dark-color);
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px
}

footer > a > img {
  width: 150px;
}