.LoadingView {
  background-color: var(--app-background-body-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

@media only screen and (max-width: 450px) {
  .LoadingView {
    background-color: var(--app-background-color);
  }
}